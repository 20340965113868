import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useCallback, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader, CardTitle,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, FormGroup,
  Input,
  Row,
} from "reactstrap"
import Select from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getProduct, clearProductNotify, deleteProduct } from "store/actions"
import ProductTable from "./ProductTable"
import { debounce } from "lodash"
import { hasAccess, timestampFileName, toastMessage } from "helpers/utils"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import { getProductToExcel, getProductUploadTemplate } from "store/product/saga"
import UploadModalForm from "containers/product/UploadModalForm"
import { getAllClothingSizesRequest } from "../../store/clothing-size/saga"
import ProductPrintPreviewModel from "./ProductPrintPreviewModel"
import { locationService } from "../../services/location-service"
import { permission } from "../../constants/permission"
import { codeColorService } from "../../services/code-color-service"
import NumberFormat from "react-number-format"
import { categoryService } from "../../services/category-service"

const ProductList = props => {
  const { 
    product,
    getProduct,
    clearProductNotify,
    deleteProduct,
    history  
  } = props;
  
  const {
    message,
    items,
    item,
    totalOnHand,
    totalOutgoing,
    totalIn,
    loading,
    itemCount,
    pageCount,
  } = product
  
  const [ id, setId ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ page, setPage ] = useState(1)
  const [ term, setTerm ] = useState("")
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])
  const [ codeColorOptions, setCodeColorOptions ] = useState([])
  const [ purchaseOrders, setPurchaseOrders ] = useState([])
  const [ locations, setLocations ] = useState([])
  const [ locationOptions, setLocationOptions ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ categoryOptions, setCategoryOptions ] = useState([])
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)
  const [ modalUpload, setModalUpload ] = useState(false)
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false);
  const [ collapse, setCollapse ] = useState(false)

  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )
  
  useEffect(() => {
    getProduct({
      term,
      page,
      pageSize,
      locationIds: locations.map(a => {
        return a.value
      }),
    
      categoryIds: categories.map(a => {
        return a.value
      })
    })
  }, [ term, page, pageSize, locations, categories ])

  useEffect(() => {
    if (message) {
      toastMessage(message)
      clearProductNotify()
    }
  }, [])

  useEffect(() => {
    getAllClothingSizesRequest().then(data => {
      if (data) {
        setClothingSizeOptions(data.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.name
          }
        }))
      }
    })

    locationService.getRequest().then(data => {
      setLocationOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })

    codeColorService.getRequest().then(data => {
      setCodeColorOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.code,
            }
          })
      )
    })

    categoryService.getRequest().then(data => {
      setCategoryOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })
    
  }, [])

  const handleOnPageChange = page => {
    setPage(page)
  }

  const handleOnPageSizeChange = pageSize => {
    setPage(1)
    setPageSize(pageSize)
  }

  const handleOnSearch = e => {
    const { value } = e.target
    setPage(1);
    debouncedFetchData(value)
  }

  const handleOnEdit = id => {
    history.push(`/product/${ id }`)
  }

  const handleOnAddNew = () => {
    history.push("/product/add-new")
    // setModalIsOpen(true);
  }

  const handleConfirmDelete = id => {
    setId(id)
    setModalConfirmDeleteIsOpen(true)
  }

  const handleDelete = () => {
    deleteProduct(id)
    setModalConfirmDeleteIsOpen(false)
  }

  const handleSubmitUpload = () => {
    setModalUpload(false)
    getProduct({ term, page, pageSize })
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Product" breadcrumbItem="Product List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ handleOnSearch }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                        <div className="text-sm-end me-3">
                          { hasAccess(permission.product.write) &&
                              <Button
                                  type="button"
                                  color="primary"
                                  className="me-1"
                                  onClick={ handleOnAddNew }
                              >
                                <i className="fas fa-plus"/> Add New
                              </Button>
                          }
                          <ButtonDropdown
                              isOpen={ moreActionIsOpen }
                              toggle={ () => setMoreActionIsOpen(!moreActionIsOpen) }
                          >
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={ () => {
                                    getProductUploadTemplate(
                                        `${ timestampFileName() }_product-upload-template.xlsx`
                                    )
                                  } }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-download me-1"/>{ " " }
                                Download Import Template
                              </DropdownItem>
                              <DropdownItem divider/>
                              <DropdownItem
                                  onClick={ () => {
                                    getProductToExcel(
                                        `${ timestampFileName() }_Finished_Goods.xlsx`
                                    )
                                  } }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-export me-1"/> Export
                                to Excel
                              </DropdownItem>

                              <DropdownItem
                                  onClick={ () => setModalUpload(true) }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-import me-1"/> Import
                                From Excel
                              </DropdownItem>
                              <DropdownItem divider/>
                              <DropdownItem
                                  onClick={ () => setModalPrintPreview(true) }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-pdf me-1"/> Export To PDF
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                    <div
                        style={{
                          position: "absolute",
                          top: "1rem",
                          right: "0.5rem",
                          cursor: "pointer",
                        }}
                        onClick={()=> setCollapse(!collapse)}
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                            className={
                              collapse
                                  ? "fas fa-angle-up"
                                  : "fas fa-angle-down"
                            }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={ collapse }>
                    <CardBody>
                      <Row>
                        <Col>
                          <Select
                              name="locations"
                              value={ locations }
                              onChange={ option => {
                                setPage(1);
                                setLocations(option)
                              } }
                              options={ locationOptions }
                              classNamePrefix="select2-selection"
                              placeholder={ "Locations" }
                              isClearable
                              isMulti
                          />
                        </Col>
                        <Col>
                          <Select
                              name="categories"
                              value={ categories }
                              onChange={ option => {
                                setPage(1);
                                setCategories(option)
                              } }
                              options={ categoryOptions }
                              classNamePrefix="select2-selection"
                              placeholder={ "Category" }
                              isClearable
                              isMulti
                          />
                        </Col>
                         <Col>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                { items && (
                    <>
                      <Card className="mb-2">
                        <CardHeader className="bg-transparent border">
                          <CardTitle>Summary</CardTitle>
                          <Row>
                            <Col md={ 12 }>
                              <div className="d-flex flex-row">
                                <Card
                                    color="primary"
                                    className="mini-stats-wid mt-1 mb-2 border me-1 w-100"
                                >
                                  <CardBody className="p-2">
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-white font-size-12 fw-bold">On Hand</p>
                                        <h4 className="mb-0 text-white text-center">
                                          <NumberFormat
                                              value={ totalOnHand }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </h4>
                                      </div>
                                      <div
                                          className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                          style={ {
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          } }
                                      >
                        <span className="avatar-title bg-transparent">
                          <i className={ "fas fa-warehouse font-size-24" }/>
                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                                <Card
                                    color="success"
                                    className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100"
                                >
                                  <CardBody className="p-2">
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-white font-size-12 fw-bold">IN</p>
                                        <h4 className="mb-0 text-white text-center">
                                          <NumberFormat
                                              value={ totalIn }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </h4>
                                      </div>
                                      <div
                                          className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                                          style={ {
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          } }
                                      >
                        <span className="avatar-title bg-transparent">
                          <i className={ "fas fa-boxes font-size-24" }/>
                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                                <Card
                                    color="info"
                                    className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100"
                                >
                                  <CardBody className="p-2">
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-white font-size-12 fw-bold">
                                          Outgoing
                                        </p>
                                        <h4 className="mb-0 text-white text-center">
                                          <NumberFormat
                                              value={ totalOutgoing }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </h4>
                                      </div>
                                      <div
                                          className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                          style={ {
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          } }
                                      >
                        <span className="avatar-title bg-transparent">
                          <i className={ "fas fa-truck-loading font-size-24" }/>
                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>

                                <Card
                                    color="danger"
                                    // onClick={() =>
                                    //     onStatusFilter({
                                    //       invoiceIds: unpaidInvoiceIds,
                                    //       paymentStatus: { key: 3, value: 3, label: "Unpaid" },
                                    //     })
                                    // }
                                    className="mini-stats-wid mt-1 mb-2 border ms-1 w-100"
                                >
                                  <CardBody className="p-2">
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <p className="text-white font-size-12 fw-bold">
                                          Return
                                        </p>
                                        <h4 className="mb-0 text-white text-center">
                                          <NumberFormat
                                              value={ 0 }
                                              displayType="text"
                                              thousandSeparator={ true }
                                              fixedDecimalScale={ false }
                                          />
                                        </h4>
                                      </div>
                                      <div
                                          className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                          style={ {
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          } }
                                      >
                        <span className="avatar-title bg-transparent">
                          <i className={ "mdi mdi-close-thick font-size-24" }/>
                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader>
                      </Card>

                      <ProductTable
                          items={ items }
                          itemCount={ itemCount }
                          currentPage={ page }
                          totalPage={ pageCount }
                          loading={ loading }
                          defaultPageSize={ pageSize }
                          onEdit={ handleOnEdit }
                          onConfirmDelete={ handleConfirmDelete }
                          onChangePage={ handleOnPageChange }
                          onPageSizeChange={ handleOnPageSizeChange }
                      />
                    </>
                ) }

                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () =>
                        setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                    }
                    onSubmit={ handleDelete }
                />

                <UploadModalForm
                    title="Import Items"
                    isOpen={ modalUpload }
                    toggle={ () => setModalUpload(!modalUpload) }
                    onSubmit={ handleSubmitUpload }
                />
                {/*<ProductPrintPreviewModel*/}
                {/*    isOpen={ modalPrintPreview }*/}
                {/*    toggle={ () => setModalPrintPreview(!modalPrintPreview) }*/}
                {/*    term={ term }*/}
                {/*    purchaseOrderIds={ purchaseOrders.map(a => {*/}
                {/*      return a.value*/}
                {/*    }) }*/}
                {/*    clothingSizeIds={ clothingSizes.map(a => {*/}
                {/*      return a.value*/}
                {/*    }) }*/}
                {/*    locationIds={ locations.map(a => {*/}
                {/*      return a.value*/}
                {/*    }) }*/}
                {/*/>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

ProductList.propTypes = {
  product: PropTypes.object,
  // items: PropTypes.array,
  // item: PropTypes.object,
  // loading: PropTypes.bool,
  // message: PropTypes.object,
  // error: PropTypes.string,
  // itemCount: PropTypes.number,
  // pageCount: PropTypes.number,
  getProduct: PropTypes.func,
  history: PropTypes.object,
  deleteProduct: PropTypes.func,
  clearProductNotify: PropTypes.func,
}

const mapStateToProps = ({ product }) => {
  return {
    product
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getProduct,
      clearProductNotify,
      deleteProduct,
    })(ProductList)
)
