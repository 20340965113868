import {
    GET_ORDER,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,
    GET_ORDER_BY_ID,
    GET_ORDER_BY_ID_SUCCESS,
    ADD_NEW_ORDER,
    ADD_NEW_ORDER_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    ORDER_CLEAR_NOTIFY,
  } from "./actionTypes";
  
  // ORDER
  export const getOrder = id => ({
    type: GET_ORDER,
    payload: id,
  });
  
  export const getOrderSuccess = Orders => ({
    type: GET_ORDER_SUCCESS,
    payload: Orders,
  });
  
  export const getOrderFail = error => ({
    type: GET_ORDER_FAIL,
    payload: error,
  });
  
  export const getOrderById = payload => ({
    type: GET_ORDER_BY_ID,
    payload,
  });
  
  export const getOrderByIdSuccess = Order => ({
    type: GET_ORDER_BY_ID_SUCCESS,
    payload: Order,
  });
  
  export const getOrderByIdFail = error => ({
    type: GET_ORDER_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewOrder = payload => ({
    type: ADD_NEW_ORDER,
    payload,
  });
  
  export const addNewOrderSuccess = payload => ({
    type: ADD_NEW_ORDER_SUCCESS,
    payload,
  });
  
  export const updateOrder = payload => ({
    type: UPDATE_ORDER,
    payload,
  });
  
  export const updateOrderSuccess = payload => ({
    type: UPDATE_ORDER_SUCCESS,
    payload,
  });
  
  export const deleteOrder = payload => ({
    type: DELETE_ORDER,
    payload,
  });
  export const deleteOrderSuccess = payload => ({
    type: DELETE_ORDER_SUCCESS,
    payload,
  });
  
  export const clearOrderNotify = () => ({
    type: ORDER_CLEAR_NOTIFY,
  });
  