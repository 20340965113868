import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/prints.css'
import NumberFormat from "react-number-format"
import moment from "moment"
import { groupByMultipleProperties } from "../../helpers/utils"

const ProductStockOutDetailToPrint = React.forwardRef((props, ref) => {
  const { item } = props
  const { productStockOutProducts: products } = item
  let clothingSizes = products.map(product => {
    return {
      id: product.clothingSizeId,
      name: product.clothingSizeName,
      quantity: product.quantity,
    }
  })

  let clothingSizeGroups = groupByMultipleProperties(clothingSizes, [ "id", "name" ])
  let productGroups = groupByMultipleProperties(products, [ "purchaseOrderCode", "codeColor" ])
  
  return (<div ref={ ref }>
    <div className="print-container">
      <div className="print-content">
        <div className="header-section">
          <h2 className="header-title" style={ { fontSize: "1.2rem" } }>K&K GARMENT CO.,LTD</h2>
          <p className={ "card-title-desc text-center" } style={ { fontSize: "0.8rem" } }>No. 22 St 02, Sangkat Chak
            Angrer Leu, Khan Mean Chey, Phnom Penh, Cambodia.</p>
          <hr/>
          <h2 className="header-title" style={ { fontSize: "1.4rem" } }>ប័ណ្ណបញ្ចេញទំនិញ</h2>
          <h2 className="header-title" style={ { fontSize: "1.4rem" } }>GOODS ISSUED NOTE</h2>
          <div className="header-section1">
            <div>
              <p>Title: <strong>{ item.name }</strong></p>
              <p>Transfer From: <strong>{ item.fromName }</strong></p>
              <p>Transfer To: <strong>{ item.toName }</strong></p>
            </div>
            <div>
              <p>Serial No: <strong>{ item.serialNumber }</strong></p>
              <p>Date: <strong>{ moment(item.date)
                  .local()
                  .format("DD-MMM-YYYY") }</strong></p>
            </div>
          </div>
        </div>
        <table className="table-print">
          <thead>
          <tr>
            <th>#</th>
            <th>PO</th>
            <th>Code Color</th>
            {
              Object.keys(clothingSizeGroups).map((k, index) => {
                return Object.keys(clothingSizeGroups[k]).map((j, index) => {
                  return <th key={ index }>
                    { j }
                  </th>
                })
              })
            }
            <th>Remark</th>
          </tr>
          </thead>
          <tbody>
          {
            Object.keys(productGroups).map((k, s) => {
              return Object.keys(productGroups[k]).map((j, index) => {
                let products = productGroups[k][j]
                return <tr key={ index }>
                  <td className={ "text-right" }>{ s + 1 }</td>
                  <td>{ k }</td>
                  <td>{ j }</td>
                  {
                    Object.keys(clothingSizeGroups).map((k1, index) => {
                      return Object.keys(clothingSizeGroups[k1]).map((j1, index) => {
                        let size = products.find(e => e.clothingSizeId === k1)
                        return <td key={ index } className={ 'text-right' }>
                          {
                              size &&
                              <NumberFormat
                                  value={ size.quantity }
                                  displayType="text"
                                  thousandSeparator={ true }
                                  fixedDecimalScale={ false }
                              />
                          }
                        </td>
                      })
                    })
                  }
                  <td></td>
                </tr>
              })
            })
          }
          </tbody>
        </table>
        <div className="footer-section">
          <div>
            <p>រៀបចំដោយ/Prepared By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/ }
          </div>
          <div>
            <p>ត្រួតពិនិត្យដោយ/Verified By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/ }
          </div>
          <div>
            <p>អនុញ្ញាត្តិដោយ/Verified By</p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/ }
          </div>
          <div>
            <p>អ្នកទទួល/Received By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/ }
          </div>
        </div>
      </div>
    </div>
  </div>)
})

ProductStockOutDetailToPrint.displayName = 'ProductStockOutDetailToPrint'

ProductStockOutDetailToPrint.propTypes = {
  item: PropTypes.object,
}

export default ProductStockOutDetailToPrint