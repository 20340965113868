import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_ERROR,
  GET_CURRENCY_BY_ID,
  GET_CURRENCY_BY_ID_SUCCESS,
  ADD_NEW_CURRENCY,
  ADD_NEW_CURRENCY_SUCCESS,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_SUCCESS,
  CURRENCY_CLEAR_ERROR,
  ADD_NEW_CURRENCY_ERROR,
} from "./actionTypes";
// Currency
export const getCurrencies = query => ({
  type: GET_CURRENCIES,
  payload: query,
});

export const getCurrenciesSuccess = payload => ({
  type: GET_CURRENCIES_SUCCESS,
  payload,
});

export const getCurrenciesError = error => ({
  type: GET_CURRENCIES_ERROR,
  payload: error,
});

export const getCurrencyById = id => ({
  type: GET_CURRENCY_BY_ID,
  payload: id,
});

export const getCurrencyByIdSuccess = payload => ({
  type: GET_CURRENCY_BY_ID_SUCCESS,
  payload,
});

export const addNewCurrency = item => ({
  type: ADD_NEW_CURRENCY,
  payload: item,
});

export const addNewCurrencySuccess = item => ({
  type: ADD_NEW_CURRENCY_SUCCESS,
  payload: item,
});

export const addNewCurrencyError = error => ({
  type: ADD_NEW_CURRENCY_ERROR,
  payload: error,
});

export const updateCurrency = item => ({
  type: UPDATE_CURRENCY,
  payload: item,
});

export const updateCurrencySuccess = item => ({
  type: UPDATE_CURRENCY_SUCCESS,
  payload: item,
});

export const deleteCurrency = item => ({
  type: DELETE_CURRENCY,
  payload: item,
});

export const deleteCurrencySuccess = item => ({
  type: DELETE_CURRENCY_SUCCESS,
  payload: item,
});

export const clearCurrencyError = () => ({
  type: CURRENCY_CLEAR_ERROR,
});

