import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RoleList from "../pages/UserManagement/Role/role-list";
import RoleDetail from "pages/UserManagement/Role/RoleDetail";
import UserList from "../pages/UserManagement/User/user-list";
import UserDetail from "../pages/UserManagement/User/UserDetail";

import UnitList from "../pages/Inventory/Unit/unit-list";

import WarehouseList from "pages/Inventory/Warehouse/warehouse-list";
import CategoryList from "pages/Inventory/Category/category-list";
import AttributeList from "pages/Inventory/Attribute/attribute-list";
// import CurrencyList from "pages/Inventory/Currency/currency-list";
import BannerList from "pages/Inventory/Banner/banner-list";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Settings from "../pages/Settings/index";
import ShippingFee from "../pages/ShippingFee/index";
import ShippingFeeDetail from "../pages/ShippingFee/detail";
import PermissionList from "../pages/UserManagement/permission/permission-list";
import InvoiceList from "pages/Invoice/InvoiceList";
import InvoiceDetail from "pages/Invoice/InvoiceDetail";
import InvoiceNoteTab from "pages/Invoice/InvoiceNoteTab";
import PaymentHistoryList from "pages/InvoicePaymentHistory/PaymentHistoryList";

import SupplierList from "pages/Supplier/SupplierList";
import CompanyList from "pages/Company/CompanyList";
import StoreList from "pages/Store/StoreList";
import ProductList from "pages/Product/ProductList";
import OrderList from "pages/SaleOrder/OrderList";
import ProductDetail from "pages/Product/ProductDetail";
import AddNewProduct from "pages/Product/AddNewProduct";
import AddNewOrder from "pages/SaleOrder/AddNewOrder";
import OrderDetail from "pages/SaleOrder/OrderDetail";
import UnitOfMeasurementList from "pages/UnitOfMeasurement/UnitOfMeasurementList";
import StockInList from "pages/StockIn/StockInList";
import StockInDetail from "pages/StockIn/StockInDetail";
import StockAdjustmentList from "pages/StockAdjustment/StockAdjustmentList";
import StockAdjustmentDetail from "pages/StockAdjustment/StockAdjustmentDetail";
import StockOutList from "pages/StockOut/StockOutList";
import StockOutDetail from "pages/StockOut/StockOutDetail";
import ProductStockInList from "pages/ProductStockIn/ProductStockInList";
import ProductStockInDetail from "pages/ProductStockIn/ProductStockInDetail";
import ProductStockOutList from "pages/ProductStockOut/ProductStockOutList";
import ProductStockOutDetail from "pages/ProductStockOut/ProductStockOutDetail";
import ClothingSizeList from "pages/ClothingSize/ClothingSizeList";
import CurrencyList from "pages/Currency/CurrencyList";
import AddNewStockIn from "../pages/StockIn/AddNewStockIn"
import AddNewStockOut from "../pages/StockOut/AddNewStockOut"
import AddNewProductStockIn from "../pages/ProductStockIn/AddNewProductStockIn"
import AddNewProductStockOut from "../pages/ProductStockOut/AddNewProductStockOut"
import AddNewStockAdjustment from "../pages/StockAdjustment/AddNewStockAdjustment"
import TransferLocationList from "../pages/TransferLocation/TransferLocationList"
import CodeColorList from "../pages/CodeColor/CodeColorList"
import LocationList from "../pages/Location/LocationList"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  

  { path: "/user", component: UserList },
  { path: "/user/:id", component: UserDetail },
  { path: "/user/:id/:activeTab", component: UserDetail },
  { path: "/role", component: RoleList },
  { path: "/role/:id", component: RoleDetail },
  { path: "/inventory/unit", component: UnitList },
  { path: "/inventory/warehouse", component: WarehouseList },
 
  { path: "/inventory/attribute", component: AttributeList },
  { path: "/setting", component: Settings },
  { path: "/shipping-fee", component: ShippingFee },
  { path: "/shipping-fee/:id", component: ShippingFeeDetail },
  { path: "/inventory/banner", component: BannerList },
  { path: "/permission", component: PermissionList },

  { path: "/invoice", component: InvoiceList },
  { path: "/invoice/:id", component: InvoiceDetail },
  { path: "/invoice/:id/:userId", component: InvoiceDetail },
  { path: "/invoiceNote", component: InvoiceNoteTab },
  { path: "/invoice-payment-history", component: PaymentHistoryList },

  { path: "/product", component: ProductList },
  { path: "/order", component: OrderList },
  { path: "/product/add-new", component: AddNewProduct },
  { path: "/order/add-new", component: AddNewOrder },
  { path: "/product/:id", component: ProductDetail },
  { path: "/order/:id", component:OrderDetail },

  { path: "/supplier", component: SupplierList },
  { path: "/company", component: CompanyList },
  { path: "/store", component: StoreList },
    

  { path: "/unit-of-measurement", component: UnitOfMeasurementList },
  { path: "/transfer-location", component: TransferLocationList },
  { path: "/code-color", component: CodeColorList },
  { path: "/location", component: LocationList },
  { path: "/clothing-size", component: ClothingSizeList },
  { path: "/currency", component: CurrencyList },
  { path: "/category", component: CategoryList },
    
  { path: "/stock-in", component: StockInList },
  { path: "/stock-in/add-new", component: AddNewStockIn },
  { path: "/stock-in/:id", component: StockInDetail },

  { path: "/stock-adjustment", component: StockAdjustmentList },
  { path: "/stock-adjustment/add-new", component: AddNewStockAdjustment },
  { path: "/stock-adjustment/:id", component: StockAdjustmentDetail },
    
  { path: "/stock-out", component: StockOutList },
  { path: "/stock-out/add-new", component: AddNewStockOut },
  { path: "/stock-out/:id", component: StockOutDetail },

  { path: "/product-stock-in", component: ProductStockInList },
  { path: "/product-stock-in/add-new", component: AddNewProductStockIn },
  { path: "/product-stock-in/:id", component: ProductStockInDetail },

  { path: "/product-stock-out", component: ProductStockOutList },
  { path: "/product-stock-out/add-new", component: AddNewProductStockOut },
  { path: "/product-stock-out/:id", component: ProductStockOutDetail },
    
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
