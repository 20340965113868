import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import OrderForm from "containers/sale-order/OrderForm";
import {
  updateOrder,
  getOrderById,
  clearOrderNotify,
} from "store/actions";
import { toastMessage } from "helpers/utils";


const OrderDetail = props => {
  const { match, history } = props;
  //const [id, setId] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const {
    getOrderById,
    updateOrder,
    clearOrderNotify,
    order,
  } = props;

  const { item, loading, message } = order;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearOrderNotify();
    }
  }, [message]);

  useEffect(() => {
    const { id } = match.params;
    getOrderById(id);
    //(id);
  }, []);

  const handleSubmit = data => {
    updateOrder({
      history,
      data,
    });
  };

  const handleOnCancel = () => {
    history.push("/order");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Detail | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            backLink={"/order"}
            breadcrumbItem={`${item?.orderNo} | Finish Good Detail`}
          />

          <Row>
            <Col md={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    General
                  </NavLink>
                </NavItem>
                <NavItem>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-primary m-1"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {item && (
                        <OrderForm
                          order={item}
                          onSubmit={handleSubmit}
                          onCancel={handleOnCancel}
                        />
                      )}
                    </>
                  )}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  updateOrder: PropTypes.func,
  getOrderById: PropTypes.func,
  clearOrderNotify: PropTypes.func,

};

const mapStateToProps = ({ order }) => {
  return {
    order,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getOrderById,
    updateOrder,
    clearOrderNotify,
  })(OrderDetail)
);
