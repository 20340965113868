import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from "react-select"
import { Input } from "reactstrap"
import { Link } from "react-router-dom"
import NumberFormat from "react-number-format"

const ProductStockInRow = props => {
  const { item, locationOptions, onChange, onDelete, isSubmitted } = props

  const [ isEditable, setIsEditable ] = useState(true)

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    onChange({
      ...item,
      [name]: value
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    onChange({
      ...item,
      [name]: valueType
    })
  }

  return (
      <tr onDoubleClick={ () => setIsEditable(!isEditable) }>
        <td className={ "input text-center pt-2" }>
          <input
              type="checkbox"
              className="form-check-input"
              checked={ item.isSelected }
              readOnly={ true }
              onChange={ handleCheckboxChange }
          />
        </td>
        <td>
          <>{ item.label }</>
        </td>
        <td className={ isEditable ? 'input' : 'text-sm-end' }>
          {
            isEditable ?
                <Input
                    type="number"
                    name="quantity"
                    step={ 0.01 }
                    className={ "text-end" + (isSubmitted && item.quantity === 0 ? ' is-invalid' : '') }
                    placeholder="Quantity"
                    value={ item.quantity }
                    min={ 0.01 }
                    onChange={ handleOnChange }
                /> :
                <div className={ isSubmitted && item.quantity === 0 ? ' is-invalid' : '' }>{ item.quantity }</div>
          }
          { isSubmitted && item.quantity === 0 && (
              <div className="invalid-feedback-custom">
                Quantity is required.
              </div>
          ) }
        </td>
        <td className={ isEditable ? 'input' : '' }>
          {
            isEditable ?
                <Select
                    name="unitOfMeasurementId"
                    value={ item.unitOfMeasurementId }
                    onChange={ handleSelectChange }
                    className={ isSubmitted && !item.unitOfMeasurementId ? "is-invalid" : '' }
                    options={ item.uomOptions }
                    classNamePrefix="select2-selection"
                    isClearable
                /> : <div className={ isSubmitted && !item.unitOfMeasurementId ? "is-invalid" : '' }>
                  { item.unitOfMeasurementId?.label }</div>
          }

          { isSubmitted && !item.unitOfMeasurementId &&
              <div className="invalid-feedback-custom">
                Unit of Measurement is required.
              </div>
          }

        </td>
        <td className={ "input text-center" }>
          <div className="d-flex gap-3 justify-content-center">
            <Link className="text-primary" to="#">
              <i
                  className={ "font-size-18 mdi " + (isEditable ? 'mdi-eye' : 'mdi-pencil') }
                  id="edittooltip"
                  onClick={ () => {
                    setIsEditable(!isEditable)
                    // getCuttingById(item.id);
                    // setModalEditIsOpen(true);
                  } }
              ></i>
            </Link>

            <Link className="text-danger" to="#">
              <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={ () => {
                    onDelete(item)
                  } }
              ></i>
            </Link>
          </div>
        </td>
      </tr>
  )
}

ProductStockInRow.propTypes = {
  item: PropTypes.object,
  locationOptions: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitted: PropTypes.bool
}

export default ProductStockInRow