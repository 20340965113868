import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { NIL } from "uuid"
import PropTypes from "prop-types"
import CreatableSelect from "react-select/creatable"
import { manufacturerService } from "services/manufacturer-service"
import { isEmpty } from "lodash"
import { locationService } from "services/location-service"
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"
import { categoryService } from "../../services/category-service"

const ProductForm = ({ product, onSubmit, onCancel }) => {
  const [ id, setId ] = useState(NIL)
  const [ code, setCode ] = useState("")
  const [ name, setName ] = useState("")
  const [ sku, setSku ] = useState("")
  const [ barcode, setBarcode ] = useState('')
  const [ purchaseOrderId, setPurchaseOrderId ] = useState(null)
  const [ itemId, setItemId ] = useState(null)

  const [ onHandQuantity, setOnHandQuantity ] = useState(0)
  const [ outgoingQuantity, setOutgoingQuantity ] = useState(0)
  const [ minQuantity, setMinQuantity ] = useState(0)

  const [ locations, setLocations ] = useState([])
  const [ locationOptions, setLocationOptions ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ categoryOptions, setCategoryOptions ] = useState([])

  const [ manufacturers, setManufacturers ] = useState([])
  const [ shortDescription, setShortDescription ] = useState("")
  const [ fullDescription, setFullDescription ] = useState("")

  const [ submitted, setSubmitted ] = useState(false)
  const [ manufacturerLoading, setManufacturerLoading ] = useState(false)
  const [ manufacturerOptions, setManufacturerOptions ] = useState([])
  const [ categoryLoading, setCategoryLoading ] = useState(false)
  const [ locationLoading, setLocationLoading ] = useState(false)

  useEffect(() => {
    if (product) {
      setId(product.id)
      setCode(product.code)
      setName(product.name)
      setSku(product.sku)
      setBarcode(product.barcode);
      setItemId(product.itemId)
      setPurchaseOrderId(product.purchaseOrderId)

      setOnHandQuantity(product.onHandQuantity)
      setOutgoingQuantity(product.outgoingQuantity)
      setMinQuantity(product.minQuantity)
      setShortDescription(product.shortDescription ?? "")
      setFullDescription(product.fullDescription ?? "")
      setManufacturers(
          product
              ? product.manufacturers
                  ? product.manufacturers.map(a => {
                    return {
                      key: a.id,
                      value: a.id,
                      label: a.name,
                    }
                  })
                  : []
              : []
      )
      setCategories(
          product.categories ?
          product.categories.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          }): []
      )
      setLocations(
          product.locations.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    }
  }, [ product ])

  useEffect(() => {
    manufacturerService.getRequest().then(data => {
      if (data) {
        setManufacturerOptions(
            data.map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              }
            })
        )
      }
    })

    locationService.getRequest().then(data => {
      setLocationOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })
    
    categoryService.getRequest().then(data => {
      setCategoryOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })
    
    
  }, [])

  const handleManufaturerChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setManufacturerLoading(true)
      manufacturerService
          .createRequest({ name: newItem.value })
          .then(data => {
            if (data) {
              setManufacturerLoading(false)
              setManufacturers(
                  manufacturers.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setManufacturerLoading(false)
          })
    } else {
      setManufacturers(newValue)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    if (code) {
      const data = {
        id,
        code,
        name,
        sku,
        barcode,
        itemId,
        purchaseOrderId,
        onHandQuantity,
        outgoingQuantity,
        minQuantity,
        shortDescription,
        fullDescription,
        manufacturers:
            manufacturers &&
            manufacturers.map(a => {
              return {
                id: a.value,
                name: a.label,
              }
            }),
        categories:
            categories &&
            categories.map(a => {
              return {
                id: a.value,
                name: a.label,
              }
            }),
        locations:
            locations &&
            locations.map(a => {
              return {
                id: a.value,
                name: a.label,
                type: a.label,
              }
            }),
      }

      onSubmit(data)
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleLocationChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setLocationLoading(true)
      locationService
          .createRequest({ name: newItem.value, type: "Item Location" })
          .then(data => {
            if (data) {
              setLocationLoading(false)
              setLocations(
                  locations.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setLocationLoading(false)
          })
    } else {
      setLocations(newValue)
    }
  }

  const handleCategoryChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setCategoryLoading(true)
      categoryService
          .createRequest({ name: newItem.value})
          .then(data => {
            if (data) {
              setCategoryLoading(false)
              setCategories(
                  categories.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setCategoryLoading(false)
          })
    } else {
      setCategories(newValue)
    }
  }

  return (
      <Row>
        <Col md={ 12 }>
          <Card className="mb-2">
            <CardBody>
              <Row>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <div
                        className={ submitted && isEmpty(name) ? "is-invalid" : "" }
                    >
                      <Label>Code</Label>
                      <Input
                          type="text"
                          id="code"
                          name="code"
                          placeholder="Code"
                          className={ submitted && isEmpty(name) ? "is-invalid" : "" }
                          value={ code }
                          onChange={ e => {
                            setCode(e.target.value)
                          } }
                      />
                    </div>
                    { submitted && isEmpty(code) && (
                        <div className="invalid-feedback-custom">
                          Code is required.
                        </div>
                    ) }
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Category</Label>

                    <CreatableSelect
                        name="categories"
                        value={ categories }
                        onChange={ handleCategoryChange }
                        options={ categoryOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ categoryLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                <FormGroup className="mb-3">
                    <Label>Name</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={ name }
                        onChange={ e => {
                          setName(e.target.value)
                        } }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Locations</Label>

                    <CreatableSelect
                        name="locations"
                        value={ locations }
                        onChange={ handleLocationChange }
                        options={ locationOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ locationLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                <FormGroup className="mb-3">
                    <Label>Barcode</Label>
                    <Input
                        type="text"
                        id="barcode"
                        name="barcode"
                        placeholder="Barcode"
                        value={ barcode }
                        onChange={ e => {
                          setBarcode(e.target.value)
                        } }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Manufacturers</Label>
                    <CreatableSelect
                        name="manufacturers"
                        value={ manufacturers }
                        onChange={ handleManufaturerChange }
                        options={ manufacturerOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ manufacturerLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={ 12 }>
                  <FormGroup className="mb-3">
                    <Label>Short Description</Label>
                    <Input
                        type="textarea"
                        id="nashortDescriptionme"
                        name="shortDescription"
                        rows={ 3 }
                        value={ shortDescription }
                        onChange={ e => {
                          setShortDescription(e.target.value)
                        } }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Full Description</Label>
                    <Input
                        type="textarea"
                        id="fullDescription"
                        name="fullDescription"
                        rows={ 3 }
                        value={ fullDescription }
                        onChange={ e => {
                          setFullDescription(e.target.value)
                        } }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <Label>On Hand Quantity</Label>
                    <Input
                        type="text"
                        id="onHandQuantity"
                        name="onHandQuantity"
                        placeholder="On Hand Quantity"
                        value={ onHandQuantity }
                        readOnly={ true }
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <Label>Out Going Quantity</Label>
                    <Input
                        type="text"
                        id="outgoingQuantity"
                        name="outgoingQuantity"
                        placeholder="Out Going Quantity"
                        value={ outgoingQuantity }
                        readOnly={ true }
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  { " " }
                  <FormGroup className="mb-3">
                    <Label>Min Quantity</Label>
                    <Input
                        type="number"
                        id="minQuantity"
                        name="minQuantity"
                        placeholder="Min Quantity"
                        value={ minQuantity }
                        onChange={ e => setMinQuantity(e.target.value) }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Col md={ 12 }>
            <Card>
              <CardBody>
                <div className="float-end">
                  { hasAccess(permission.product.write) &&
                      <Button color="primary" className="me-2" onClick={ handleSubmit }>
                        Submit{ " " }
                      </Button>
                  }
                  <Button color="primary" onClick={ handleCancel } outline>
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
  )
}

ProductForm.propTypes = {
  product: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ProductForm
