import Breadcrumbs from "components/Common/Breadcrumb"
import React, { useCallback, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Button,
  //ButtonDropdown,
  Card,
  CardBody,
  CardHeader, CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
// import Select from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getOrder, clearOrderNotify, deleteOrder } from "store/actions"
import UploadModalForm from "containers/sale-order/UploadModalForm"
import OrderTable from "./OrderTable"
import { debounce } from "lodash"

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"

const OrderList = props => {
  const { 
    order,
    getOrder,
    clearOrderNotify,
    deleteOrder,
    history  
  } = props;
  
  const {
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
  } = order
  
  const [ id, setId ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ page, setPage ] = useState(1)
  const [ term, setTerm ] = useState("")
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false)
  const [ collapse, setCollapse ] = useState(false)
  const [ modalUpload, setModalUpload ] = useState(false)

  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )
  
  useEffect(() => {
    getOrder({
      term,
      page,
      pageSize,
    })
  }, [ term, page, pageSize ])

  useEffect(() => {
    if (message) {
    //  toastMessage(message)
      clearOrderNotify()
    }
  }, [])

  const handleOnPageChange = page => {
    setPage(page)
  }

  const handleOnPageSizeChange = pageSize => {
    setPage(1)
    setPageSize(pageSize)
  }

  const handleOnSearch = e => {
    const { value } = e.target
    setPage(1);
    debouncedFetchData(value)
  }

  const handleOnEdit = id => {
    history.push(`/order/${ id }`)
  }

  const handleOnAddNew = () => {
    history.push("/order/add-new")
  }

  const handleConfirmDelete = id => {
    setId(id)
    setModalConfirmDeleteIsOpen(true)
  }

  const handleDelete = () => {
    deleteOrder(id)
    setModalConfirmDeleteIsOpen(false)
  }

  const handleSubmitUpload = () => {
    setModalUpload(false)
    getOrder({ term, page, pageSize })
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Order | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Order" breadcrumbItem="Order List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ handleOnSearch }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                        <div className="text-sm-end me-3">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div
                        style={{
                          position: "absolute",
                          top: "1rem",
                          right: "0.5rem",
                          cursor: "pointer",
                        }}
                        onClick={()=> setCollapse(!collapse)}
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                            className={
                              collapse
                                  ? "fas fa-angle-up"
                                  : "fas fa-angle-down"
                            }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                </Card>
              </Col>
              <Col md={ 12 }>
                { items && (
                    <>
                      
                      <OrderTable
                          items={ items }
                          itemCount={ itemCount }
                          currentPage={ page }
                          totalPage={ pageCount }
                          loading={ loading }
                          defaultPageSize={ pageSize }
                          onEdit={ handleOnEdit }
                          onConfirmDelete={ handleConfirmDelete }
                          onChangePage={ handleOnPageChange }
                          onPageSizeChange={ handleOnPageSizeChange }
                      />
                    </>
                ) }

                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () =>
                        setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                    }
                    onSubmit={ handleDelete }
                />

                <UploadModalForm
                    title="Import Items"
                    isOpen={ modalUpload }
                    toggle={ () => setModalUpload(!modalUpload) }
                    onSubmit={ handleSubmitUpload }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}

OrderList.propTypes = {
  order: PropTypes.object,
  getOrder: PropTypes.func,
  history: PropTypes.object,
  deleteOrder: PropTypes.func,
  clearOrderNotify: PropTypes.func,
}

const mapStateToProps = ({ order }) => {
  return {
    order
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getOrder,
      clearOrderNotify,
      deleteOrder,
    })(OrderList)
)
