import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Col,
  Table,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { NIL } from "uuid";
import Select from "react-select";
import { debounce } from "lodash";
// import { getItemsRequest } from "../../store/item/saga";
import OrderItemRow from "./OrderItemRow";
import PropTypes from "prop-types";
import { getProductRequest } from "store/product/saga";

const OrderForm = ({ order = {},  onSubmit, onCancel }) => {
  const [id, setId] = useState(NIL);
  const [orderNo, setOrderNo] = useState(order.orderNo || "");
  const [status, setStatus] = useState(order.status || "");
  const [paymentStatus, setPaymentStatus] = useState(order.paymentStatus || "");
  const [customerName, setCustomerName] = useState(order.customerName || "");
  const [productOptions, setProductOptions] = useState([]);
  const [term, setTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [isHeaderSelected, setIsHeaderSelected] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderState, setOrderState] = useState({ ...order, orderProducts: order.orderProducts || [] });


  useEffect(() => {
    setLoading(true);
    getProductRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false);
        setProductOptions(res.data.map((a, index) => {
          return {
            key: a.id,
            value: a.id,
            type: a.type,
            //itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
            quantity: a.onHandQuantity,
            label: `${a.code} - ${a.name}`
          };
        }));
      }
    });
  }, [term]);

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  const handleInputChange = (value) => {
    debouncedQuickSearch(value);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOrderItemChange = entry => {
    setOrderState({
      ...orderState,
      orderProducts: orderState.orderProducts.map(a => a.index === entry.index ? entry : a)
    });
  };

  const handleDeleteOrderItem = (entry) => {
    setOrderState({
      ...orderState,
      orderProducts: orderState.orderProducts.filter(e => e.index !== entry.index)
    });
  };

  const handleDeleteSelected = () => {
    setOrderState({ ...orderState, orderProducts: orderState.orderProducts.filter(e => !e.isSelected) });
    setIsHeaderSelected(false);
  };

  const handleHeaderSelect = e => {
    const { checked } = e.target;
    setIsHeaderSelected(checked);
    setOrderState({
      ...orderState,
      orderProducts: orderState.orderProducts.map(entry => {
        return {
          ...entry,
          isSelected: checked
        };
      })
    });
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

      let data = {
        ...orderState,
        date: orderState.date?.toISOString(),
        orderProducts: orderState.orderProducts.map(entry => {
          return {
            ...entry,
            orderId:id,
            productId: entry.productId?.value
          };
        })
      };

      console.log("Order Data to Submit:", data);
      onSubmit(data);
  };

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;

    switch (name) {
      case 'productId':
        if (valueType) {
          const { value, label } = valueType;
          let max = 0;
          if (orderState.orderProducts.length > 0) {
            max = Math.max(...orderState.orderProducts.map(a => a.index));
            max += 1;
          } else {
            max = max + 1;
          }

          let newEntry = {
            index: max,
            id: NIL,
            orderId: valueType,
            productId: valueType,
            label: label,
            quantity: 0,
            price: 0,
            discount: 0,
            isSelected: false
          };

          setOrderState({ ...orderState, orderProducts: [...orderState.orderProducts, newEntry] });
        }
        break;
    }
    
  };

  return (
    <Row>
      <Col md={12}>
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label>Order Id</Label>
                  <Input
                    type="text"
                    id="orderNo"
                    name="orderNo"
                    placeholder="Order Id"
                    value={orderNo || ''}
                    onChange={e => {
                      setOrderNo(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Customer Id</Label>
                  <Input
                    type="text"
                    id="customerName"
                    name="customerName"
                    placeholder="Customer Name"
                    value={customerName || ''}
                    onChange={e => {
                      setCustomerName(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label>Status</Label>
                  <Input
                    type="text"
                    id="statuss"
                    name="status"
                    placeholder="Status"
                    value={status || ''}
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label>Payment Status</Label>
                  <Input
                    type="text"
                    id="paymentStatus"
                    name="paymentStatus"
                    placeholder="Payment Status"
                    value={paymentStatus || ''}
                    onChange={e => {
                      setPaymentStatus(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={"mb-2"}>
          <CardHeader className={"bg-transparent border-bottom"}>
            <Row>
              <Col md={2}>
                <CardTitle className={"pt-2"}>Items</CardTitle>
              </Col>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardHeader className={"bg-transparent border-bottom"}>
            <Row>
              <Col>
                <Label className={"mt-2"}>Find Items:</Label>
              </Col>
              <Col md={8}>
                <Select
                  name="productId"
                  value={null}
                  placeholder={"Find by Code, Name, ..."}
                  onChange={handleSelectChange}
                  options={productOptions}
                  isLoading={loading}
                  onInputChange={handleInputChange}
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button
                    color={"danger"}
                    onClick={handleDeleteSelected}
                    outline
                    disabled={!orderState.orderProducts?.some(e => e.isSelected)}
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
              id="tech-companies-1"
              className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={"bg-primary text-white"}>
              <tr>
                <th className={"text-center"} style={{ width: "80px" }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="headerSelected"
                    checked={isHeaderSelected}
                    onChange={handleHeaderSelect}
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={"text-center"} style={{ width: "250px" }}>Quantity</th>
                <th className={"text-center"} style={{ width: "250px" }}>Price</th>
                <th className={"text-center"} style={{ width: "250px" }}>Discount</th>
                <th className={"text-center"} style={{ width: "120px" }}>Action</th>
              </tr>
              </thead>
              <tbody>
                {
                  orderState.orderProducts?.map((entry, index) => (
                    <OrderItemRow
                      key={index}
                      item={entry}
                      isSubmitted={isSubmitted}
                      onChange={handleOrderItemChange}
                      onDelete={handleDeleteOrderItem}
                    />
                  ))
                } 
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Card>
        </Card>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" className="me-2" onClick={handleSubmit}>
                  Submit{" "}
                </Button>
                <Button color="primary" onClick={handleCancel} outline>
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Col>
    </Row>
  );
};

OrderForm.propTypes = {
  order: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default OrderForm;

