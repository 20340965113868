import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"
import { sumBy } from "lodash"

const ProductTable = ({
  itemCount,
  currentPage,
  totalPage,
  defaultPageSize,
  items,
  loading,
  onEdit,
  onConfirmDelete,
  onChangePage,
  onPageSizeChange,
}) => {
  const displayLocations = items => {
    return items.map((a, index) => {
      return (
        <Badge key={index} color="primary" className="px-2 py-1 mx-1">
          {a.name}
        </Badge>
      );
    });
  };

  const displayCategory = items => {
    return items.map((a, index) => {
      return (
          <Badge key={index} color="primary" className="px-2 py-1 mx-1">
            {a.name}
          </Badge>
      );
    });
  };
  return (
    <Card>
      <CardBody>
        <div className="table-rep-plugin">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                  <tr>
                    <th style={{ width: "80px" }}>#</th>
                    <th style={{ width: "120px" }}>Action</th>
                    <td></td>
                    <th className="text-center">Code</th>
                    <th className="text-center">Barcode</th>
                    {/* <th className="text-center">Size</th>
                    <th className="text-center">Code Color</th> */}
                    <th className="text-center">Location</th>
                    <th className="text-center">Category</th>
                    <th className="text-center">PO</th>
                    <th className="text-center">On Hand</th>
                    <th className="text-center">Outgoing</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    let num = (currentPage - 1) * defaultPageSize + (index + 1);
                    const { productImages, stockUoMs, locations, categories, stockLocations } = item;
                    let productImage = productImages.length >0 ? productImages[0]: null;
                    
                    let totalOnHand = sumBy(stockLocations, stockLocation=>{
                      return stockLocation.onHand
                    });

                    let totalOutgoing = sumBy(stockLocations, stockLocation=>{
                      return stockLocation.outgoing
                    });
                    
                    return (
                      <tr key={index} onDoubleClick={()=>onEdit(item.id)} style={{cursor:"pointer"}}>
                        <td style={{ textAlign: "right" }}>{num}</td>
                        <td>
                          <div className="d-flex gap-3">
                            { hasAccess(permission.product.read) &&
                            <Link className="text-primary" to="#">
                              <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => onEdit(item.id)}
                              ></i>
                            </Link>
                            }

                            { hasAccess(permission.product.delete) &&
                                <Link className="text-danger" to="#">
                              <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => onConfirmDelete(item.id)}
                              ></i>
                            </Link>
                            }
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {
                              productImage ?
                                  <img style={{width:"80px", height:"50px", objectFit:"contain"}} src={productImage.url} alt=""/>
                                  :
                                  <div className="avatar-order">
                                    <i className="fas fa-tshirt text-primary"></i>
                                  </div>      
                            }
                          </div>
                        </td>
                        <td>
                          <h5 className={"font-size-14 mb-1"}>
                            {item.code}
                          </h5>
                          <p className="text-muted mb-0">
                            {item.name}
                          </p>
                        </td>
                        <td>{item.barcode}</td>
                        {/* <td>
                          {item.clothingSizeName}
                        </td>
                        <td>
                          {item.codeColorName}
                        </td> */}
                        <td>{displayLocations(locations)}</td>
                        <td>{displayCategory(categories)}</td>
                        <td>{item.purchaseOrderCode}</td>
                        <td className="text-end">
                          <NumberFormat
                            value={item.onHandQuantity}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </td>
                        <td className="text-end">
                          <NumberFormat
                            value={item.outgoingQuantity}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={currentPage}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </CardBody>
    </Card>
  );
};

ProductTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default ProductTable;
