import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import OrderForm from "containers/sale-order/OrderForm";
import {
    addNewOrder,
    clearOrderNotify,
    getOrderById,
} from "store/actions";
import { toastMessage } from "helpers/utils";

const AddNewOrder = props => {
  const { match, history } = props;

  const [activeTab, setActiveTab] = useState("1");

  const { addNewOrder, clearOrderNotify, order } = props;
  const { item, loading, message } = order;

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearOrderNotify();
    }
  }, [message]);

  const handleSubmit = data => {
    addNewOrder({
      history,
      data,
    });
  };

  const handleOnCancel = () => {
    history.push("/order");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add New Order | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" backLink={"/order"} breadcrumbItem={``} />

          <Row>
            <Col md={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    General
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <OrderForm
                    onSubmit={handleSubmit}
                    onCancel={handleOnCancel}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddNewOrder.propTypes = {
    order: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  addNewOrder: PropTypes.func,
  clearOrderNotify: PropTypes.func,
};

const mapStateToProps = ({ order }) => {
  return {
    order,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addNewOrder,
    clearOrderNotify,
  })(AddNewOrder)
);
