import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Table } from "reactstrap";

const OrderTable = ({
  itemCount,
  currentPage,
  totalPage,
  defaultPageSize,
  items,
  loading,
  onEdit,
  onConfirmDelete,
  onChangePage,
  onPageSizeChange,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault(); 
    console.log("Form submitted!");
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
                >
                  <thead>
                    <tr>
                      <th style={{ width: "80px" }}>#</th>
                      <th style={{ width: "120px" }}>Action</th>
                      <th style={{ width: "120px" }}>Order No</th>
                      <th style={{ width: "120px" }}>Status</th>
                      <th className="text-center">Payment Status</th>
                      <th className="text-center">Customer Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);

                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => onEdit(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td style={{ textAlign: "right" }}>{num}</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-primary" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => onEdit(item.id)}
                                ></i>
                              </Link>

                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => onConfirmDelete(item.id)}
                                ></i>
                              </Link>
                            </div>
                          </td>
                          <td>{item.orderNo}</td>
                          <td>{item.status}</td>
                          <td>{item.paymentStatus}</td>
                          <td>{item.customerName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={(i) => onChangePage(i)}
            onPageSizeChange={(size) => onPageSizeChange(size)}
          />
          {/* Submit button */}
          {/* <button type="submit" className="btn btn-primary">
            Submit
          </button> */}
        </form>
      </CardBody>
    </Card>
  );
};

OrderTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default OrderTable;
