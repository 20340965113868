import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PRODUCT,
  ADD_NEW_PRODUCT,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import FileSaver from "file-saver";
import {
  getProductSuccess,
  getProductByIdSuccess,
  getProductFail,
  addNewProductSuccess,
  updateProductSuccess,
  deleteProductSuccess,
} from "./actions";

const getProductRequest = query => post(`/api/Product/paging`, query);
const getAllProductRequest = query => post(`/api/Product/all`, query);
const addNewProductRequest = item => post(`/api/Product`, item);
const getProductByIdRequest = id => get(`/api/Product/${id}`);
const updateProductRequest = item => update(`/api/Product`, item);
const deleteProductRequest = id => del(`/api/Product/${id}`);

const getProductUploadTemplate = async fileName => {
  return await getFile(`/api/Product/uploadTemplate`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getProductToExcel = async (fileName) => {
  return await getFile(`/api/Product/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* fetchProducts({ payload }) {
  try {
    const response = yield call(getProductRequest, payload);

    yield put(getProductSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductFail(error));
  }
}

function* addNewProduct({ payload }) {
  try {
    const { history, data } = payload;
    const response = yield call(addNewProductRequest, data);
    yield put(addNewProductSuccess(response));
    history.push("/product");
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductFail(error));
  }
}

function* updateProduct({ payload }) {
  try {
    const { history, data } = payload;
    var response = yield call(updateProductRequest, data);
    yield put(updateProductSuccess(response));
    history.push("/product");
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductFail(error));
  }
}

function* getProductById({ payload }) {
  try {
    var response = yield call(getProductByIdRequest, payload);
    yield put(getProductByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductFail(error));
  }
}

function* deleteProduct({ payload }) {
  try {
    yield call(deleteProductRequest, payload);
    const response = yield call(getProductRequest, {});
    yield put(getProductSuccess(response));
    yield put(deleteProductSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductFail(error));
  }
}

function* ProductSaga() {
  yield takeEvery(GET_PRODUCT, fetchProducts);
  yield takeEvery(ADD_NEW_PRODUCT, addNewProduct);
  yield takeEvery(GET_PRODUCT_BY_ID, getProductById);
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

export default ProductSaga;

export { getProductRequest, getAllProductRequest, getProductUploadTemplate, getProductToExcel };
