import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_CURRENCY,
  GET_CURRENCY_BY_ID,
  UPDATE_CURRENCY,
  DELETE_CURRENCY,
  GET_CURRENCIES,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCurrencyByIdSuccess,
  getCurrenciesError,
  getCurrenciesSuccess,
} from "./actions";

const getCurrenciesRequest = query =>
  get("/api/Currency/paging", { params: query });
const getAllCurrenciesRequest = () => get("/api/Currency");
const addNewCurrencyRequest = item => post("/api/Currency", item);
const getCurrencyByIdRequest = id => get(`/api/Currency/${id}`);
const updateCurrencyRequest = item => update("/api/Currency", item);
const deleteCurrencyRequest = id => del(`/api/Currency/${id}`);

function* getCurrencies({ payload }) {
  try {
    const response = yield call(getCurrenciesRequest, payload);
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesError(error));
  }
}

function* addNewCurrency({ payload }) {
  try {
    yield call(addNewCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesError(error));
  }
}

function* updateCurrency({ payload }) {
  try {
    yield call(updateCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesError(error));
  }
}

function* getCurrencyById({ payload }) {
  try {
    var response = yield call(getCurrencyByIdRequest, payload);
    yield put(getCurrencyByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesError(error));
  }
}

function* deleteCurrency({ payload }) {
  try {
    yield call(deleteCurrencyRequest, payload);
    const response = yield call(getCurrenciesRequest, {});
    yield put(getCurrenciesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getCurrenciesError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_CURRENCIES, getCurrencies);
  yield takeEvery(ADD_NEW_CURRENCY, addNewCurrency);
  yield takeEvery(GET_CURRENCY_BY_ID, getCurrencyById);
  yield takeEvery(UPDATE_CURRENCY, updateCurrency);
  yield takeEvery(DELETE_CURRENCY, deleteCurrency);
}

export default itemSaga;

export { getAllCurrenciesRequest };
