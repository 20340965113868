
import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";

const CurrencyTable = ({
  itemCount,
  page,
  totalPage,
  defaultPageSize,
  items,
  loading,
  onEdit,
  onConfirmDelete,
  onChangePage,
  onPageSizeChange,
}) => {
  return (
    <Card>
      <CardBody>
        <div className="table-rep-plugin">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Symbol</th>
                    <th>Is Default</th>
                    <th>Display Order</th>
                    <th>Rate</th>
                    <th style={{ width: "120px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    let num = (page - 1) * defaultPageSize + (index + 1);
                    const isDefault = item.isDefault ? "Yes" : "";
                    return (
                      <tr key={index}>
                        <td>{item.label}</td>
                        <td>{item.symbol}</td>
                        <td>{isDefault}</td>
                        <td>{item.displayOrder}</td>
                        <td>
                           <div>
                                <NumberFormat
                                  value={Number(item.rate).toFixed(2)}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                  displayType="text"
                                />
                              </div>
                          </td>
                        <td>
                          <div className="d-flex gap-3">
                            <Link className="text-primary" to="#">
                              <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                                onClick={() => onEdit(item.id)}
                              ></i>
                            </Link>

                            <Link className="text-danger" to="#">
                              <i
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                                onClick={() => onConfirmDelete(item.id)}
                              ></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <CustomPagination
          itemCount={itemCount}
          currentPage={page}
          totalPage={totalPage}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
          onChangePage={i => onChangePage(i)}
          onPageSizeChange={size => onPageSizeChange(size)}
        />
      </CardBody>
    </Card>
  );
};

CurrencyTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default CurrencyTable;


