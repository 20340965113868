import { SEVERITY } from "helpers/utils.js";
import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCY_BY_ID_SUCCESS,
  GET_CURRENCIES_ERROR,
  CURRENCY_CLEAR_ERROR,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const currency = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CURRENCIES:
      return {
        ...state,
        loading: true,
      };
    case GET_CURRENCIES_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    case GET_CURRENCY_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_CURRENCIES_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_Currency");
      return {
        ...state,
        message: {
          title: "Currency",
          text: hasUniqueCode ? "Currency is duplicate Name." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case CURRENCY_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default currency;

