import { SEVERITY } from "helpers/utils.js";
import {
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_ORDER_BY_ID_SUCCESS,
  ADD_NEW_ORDER,
  ADD_NEW_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  ORDER_CLEAR_NOTIFY,
  GET_ORDER_BY_ID,
} from "./actionTypes.js";
const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  totalOnHand: 0,
  totalOutgoing: 0,
  totalIn: 0,
  loading: false,
  message: null,
};

const order = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_ORDER:
    case UPDATE_ORDER:
    case DELETE_ORDER:
    case GET_ORDER:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_SUCCESS:
      const { itemCount, pageCount, totalOnHand, totalOutgoing, totalIn , data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        totalOnHand,
        totalOutgoing,
        totalIn,
        items: data,
        loading: false,
      };

    case GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };

    case GET_ORDER_FAIL:
      let hasUniqueCode = payload.includes("UNIQUE_Order_Code");
      return {
        ...state,
        loading: false,
        message: {
          title: "Order",
          text: hasUniqueCode ? "Order is duplicate code." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case GET_ORDER_BY_ID:
      return {
        ...state,
        item: null,
      };

    case ADD_NEW_ORDER_SUCCESS:
      return {
        ...state,
        item: null,
        loading: false,
        message: {
          title: "Order",
          text: "Order has been added successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        item: null,
        loading: false,
        message: {
          title: "Order",
          text: "Order has been updated successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: "Order",
          text: "Order has been deleted successfully.",
          severity: SEVERITY.INFO,
        },
      };

    case ORDER_CLEAR_NOTIFY:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default order;
