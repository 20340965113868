export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_ERROR = "GET_CURRENCIES_ERROR";

export const GET_CURRENCY_BY_ID = "GET_CURRENCY_BY_ID";
export const GET_CURRENCY_BY_ID_SUCCESS =
  "GET_CURRENCY_BY_ID_SUCCESS";

export const ADD_NEW_CURRENCY = "ADD_NEW_CURRENCY";
export const ADD_NEW_CURRENCY_SUCCESS = "ADD_NEW_CURRENCY_SUCCESS";
export const ADD_NEW_CURRENCY_ERROR = "ADD_NEW_CURRENCY_ERROR";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_ERROR = "UPDATE_CURRENCY_ERROR";

export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";

export const CURRENCY_CLEAR_ERROR = "CURRENCY_CLEAR_ERROR";

