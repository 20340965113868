import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";
import {
  getCurrencies,
  addNewCurrency,
  updateCurrency,
  getCurrencyById,
  deleteCurrency,
  clearCurrencyError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

import { debounce } from "lodash";
import ModalForm from "containers/currency/ModalForm";
import CurrencyTable from "containers/currency/CurrencyTable";

const CurrencyList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);

  const {
    getCurrencies,
    addNewCurrency,
    deleteCurrency,
    updateCurrency,
    clearCurrencyError,
    getCurrencyById,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
  } = props;

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  useEffect(() => {
    getCurrencies({ page, pageSize, term });
  }, [page, pageSize, term]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearCurrencyError();
    }
  }, [message]);

  const handleOnEdit = id => {
    getCurrencyById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    updateCurrency(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = item => {
    addNewCurrency(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteCurrency(id);
    setModalConfirmDeleteIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Currency List | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Currency List"
            breadcrumbItem="Currency List"
          />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => {
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <CurrencyTable
                  items={items}
                  itemCount={itemCount}
                  page={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={page => setPage(page)}
                  onPageSizeChange={pageSize => {
                    setPage(1);
                    setPageSize(pageSize);
                  }}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Currency"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Currency"}
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() =>
                  setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                }
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CurrencyList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,

  getCurrencies: PropTypes.func,
  addNewCurrency: PropTypes.func,
  updateCurrency: PropTypes.func,
  getCurrencyById: PropTypes.func,
  deleteCurrency: PropTypes.func,
  clearCurrencyError: PropTypes.func,
};

const mapStateToProps = ({ currency }) => {
  return currency;
};

export default withRouter(
  connect(mapStateToProps, {
    getCurrencies,
    addNewCurrency,
    updateCurrency,
    getCurrencyById,
    deleteCurrency,
    clearCurrencyError,
  })(CurrencyList)
);

