import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ORDER,
  ADD_NEW_ORDER,
  GET_ORDER_BY_ID,
  UPDATE_ORDER,
  DELETE_ORDER,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import FileSaver from "file-saver";
import {
  getOrderSuccess,
  getOrderByIdSuccess,
  getOrderFail,
  addNewOrderSuccess,
  updateOrderSuccess,
  deleteOrderSuccess,
} from "./actions";

const getOrderRequest = query => post(`/api/Order/paging`, query);
const getAllOrderRequest = query => post(`/api/Order/all`, query);
const addNewOrderRequest = item => post(`/api/Order`, item);
const getOrderByIdRequest = id => get(`/api/Order/${id}`);
const updateOrderRequest = item => {
  return update(`/api/Order`, item);
}
const deleteOrderRequest = id => del(`/api/Order/${id}`);


const getOrderUploadTemplate = async fileName => {
  return await getFile(`/api/Order/uploadTemplate`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

const getOrderToExcel = async (fileName) => {
  return await getFile(`/api/Order/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* fetchOrders({ payload }) {
  try {
    const response = yield call(getOrderRequest, payload);

    yield put(getOrderSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderFail(error));
  }
}

function* addNewOrder({ payload }) {
  try {
    const { history, data } = payload;
    const response = yield call(addNewOrderRequest, data);
    yield put(addNewOrderSuccess(response));
    history.push("/order");
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderFail(error));
  }
}

function* updateOrder({ payload }) {
  try {
    const { history, data } = payload;
    var response = yield call(updateOrderRequest, data);
    yield put(updateOrderSuccess(response));
    history.push("/order");
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderFail(error));
  }
}

function* getOrderById({ payload }) {
  try {
    var response = yield call(getOrderByIdRequest, payload);
    yield put(getOrderByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderFail(error));
  }
}

function* deleteOrder({ payload }) {
  try {
    yield call(deleteOrderRequest, payload);
    const response = yield call(getOrderRequest, {});
    yield put(getOrderSuccess(response));
    yield put(deleteOrderSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getOrderFail(error));
  }
}

function* OrderSaga() {
  yield takeEvery(GET_ORDER, fetchOrders);
  yield takeEvery(ADD_NEW_ORDER, addNewOrder);
  yield takeEvery(GET_ORDER_BY_ID, getOrderById);
  yield takeEvery(UPDATE_ORDER, updateOrder);
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

export default OrderSaga;

export { getOrderRequest, getAllOrderRequest, getOrderUploadTemplate, getOrderToExcel };
